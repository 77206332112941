import { Directive, Input, ViewContainerRef, Compiler, ModuleWithComponentFactories, Type, NgModule, Component, OnChanges, ComponentRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import * as $ from 'jquery';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxBarcodeModule } from "ngx-barcode";

@Directive({
  selector: '[compile]'
})
export class CompileDirective implements OnChanges {
  @Input() compile: any;
  @Input() compileContext: any;


  compRef: ComponentRef<any>;

  compiletemplate: any = ""
  compilestyle: string[];
  pagesize: any = 1180
  pagetype: any = ""

  constructor(private vcRef: ViewContainerRef, private compiler: Compiler) { }



  ngOnChanges() {
    console.log(this.compile)

    if (!this.compile) {
      if (this.compRef) {
        this.updateProperties();
        return;
      }
      throw Error('You forgot to provide template');
    }
    else {
      this.compiletemplate = this.compile.template
      this.compilestyle = this.compile.templatecss
      this.pagesize = this.compile.pagesize
      this.pagetype = this.compile.pagetype

      console.log(this.compile)
    }

    this.vcRef.clear();
    this.compRef = null;

    const component = this.createDynamicComponent(this.compiletemplate, this.compilestyle);
    const module = this.createDynamicModule(component);

    this.compiler.compileModuleAndAllComponentsAsync(module)
      .then((moduleWithFactories: ModuleWithComponentFactories<any>) => {
        let compFactory = moduleWithFactories.componentFactories.find(x => x.componentType === component);

        this.compRef = this.vcRef.createComponent(compFactory);
        this.updateProperties();
      })
      .catch(error => {
        console.log(error);
      });
  }

  updateProperties() {

    console.log(this.compileContext)
    for (var prop in this.compileContext) {
      console.log(prop)
      console.log(this.compileContext[prop])
      this.compRef.instance[prop] = this.compileContext[prop];
    }



    setTimeout(() => {
      if(this.pagetype!=='REPORT' && this.pagetype!=='REPORTLANDSCAPE'){
        this.SetUpInvoiceDesign()
      }
      else if(this.pagetype==='REPORTLANDSCAPE'){
        let styleElement = document.getElementById('dvprint');

        const style = document.createElement('style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode('@media print { @page { size: A4 landscape;margin: 6px; } }'));
        styleElement.appendChild(style);
  
      }
      
     // window.print()
    }, 1000)
  }




  SetUpInvoiceDesign() {


    if (this.pagetype == "H") {
      let styleElement = document.getElementById('dvprint');

      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode('@media print { @page { size: A4 landscape;margin: 6px; } }'));
      styleElement.appendChild(style);

    }

    var pagewidth=null;
    if (this.pagetype == "H") {
      pagewidth='297mm'
    }
    else{
      pagewidth='210mm'
    }
    console.log("Page width")
    console.log(pagewidth)


 
    //$(this).find(".page-copy-container-wrapper").width(2480);

    var totalpage = this.pagesize;
    $(".page-copy-container-wrapper").each(function () {

      console.log("inner Page Size")
      console.log(totalpage)

      //var totalpage = 1180;
      //var totalpage = 1190;        
      var header = $(this).find(".page-wrapper .page-header").height();
      var footer = $(this).find(".page-wrapper .page-footer").height();
      var contentheight = totalpage - (Number(header) + Number(footer));
      console.log('headerheight:' + header);
      console.log('footerheight:' + footer);
      console.log('contentheight:' + contentheight);

      $(this).find(".page-wrapper .page-content").height(contentheight);
      $(this).find(".tableboxLine").height(contentheight);


      $(this).find(".page-wrapper").css('width', pagewidth);
   
     

      var countPages = 1;
      var countHeight = 0;

      var s = -1;
      $(this).find("#billdetailstbodys tr").each(function () {
        s = s + 1
        console.log(s)
        countHeight += Number($(this).height());
        console.log($(this).height())
        console.log(countHeight)
        // console.log(contentheight-10)
        // console.log(contentheight)
        // console.log(countHeight)
        // console.log(countPages)
        console.log('Number',contentheight + Number(100))

        if (countHeight > ((contentheight-Number(30)))) {
          countPages++;
          countHeight = 0
          //countHeight = Number($(this).height());
        }
        

        // if (countHeight > ((contentheight + Number(100)))) {
        //   countPages++;
        //   countHeight = Number($(this).height());
        // }
        // else if (((contentheight + Number(100)) - countHeight) < 8) {
        //   countPages++;
        //   countHeight = Number($(this).height());
        // }
        $(this).addClass("pageRow" + countPages);
      });

      var page_wrapper = $(this).find(".page-wrapper-tr");
      for (var i = 1; i < countPages; i++) {
        page_wrapper.clone().appendTo($(this).find(".page-wrapper-table"));
      }


      var counter = 1;
      $(this).find(".page-wrapper").each(function () {
        $(this).find(".billdetailstbody tbody tr:not(.pageRow" + counter + ")").remove();
        counter++;
      });


    });

    var wrapper = $(".page-copy-container-wrapper");
    var count = wrapper.length;
    if (count > 1) {
      var htmlToMove = "";
      for (var i = 0; i < count; i++) {
        htmlToMove += wrapper.eq(i).html();
      }
      wrapper.first().html(htmlToMove);
      wrapper.not(":first").remove();
    }








    var wrapper = $(".page-wrapper-tr");
    var scount = wrapper.length;
    console.log("UPDATES");

    if (scount > 1) {

      $(".page-wrapper-tr").each(function (this, index) {

        var wrapper = $(".page-wrapper-tr");
        var scount = wrapper.length;
        
        
        console.log(this)


        if (index != (scount - 1)) {
          // $(this).find(".page-wrapper .page-footer").css('display','none');
          // $(this).find(".page-wrapper .page-footerfirst").css('display','block');
          // footer.addClass("")

          $(this).find(".page-footer").find("table").each(function (index, tr) {
            $(tr).css('color', 'white');
            $(tr).css('border', 'white');


            $(tr).each(function (sindex, td) {
              $(td).css('border', 'white');
            });

            $(tr).each(function (sindex, td) {
              $(td).find(".summaryheader").css('color', '#495057');
            });



            console.log(index);
          });;


          $(this).find(".hpage-footer").each(function (index, tr) {
            $(tr).css('display', 'none');
            $(this).addClass("hidedisplay");
          });
          console.log("Display None");

          // $('table').each(function (index, tr) {
          //   $(tr).css('color', 'white');
          //   console.log(index);
          // });

        }
        else {

          $(this).find(".hpage-footer-line").each(function (index, tr) {
            $(tr).css('display', 'none');
            $(this).addClass("hidedisplay");
          });

          //$(this).find(".hpage-footer-line").css('display','block');
          console.log("Display Block");
          // $(this).find(".page-wrapper .page-footerfirst").css('display','none');
        }


      })
    }



  }


  private createDynamicComponent(template: string, compilestyle: string[]) {
    @Component({
      selector: 'custom-dynamic-component',
      template: template,
      styles: compilestyle
    })
    class CustomDynamicComponent {




    }
    return CustomDynamicComponent;
  }

  private createDynamicModule(component: Type<any>) {
    @NgModule({
      // You might need other modules, providers, etc...
      // Note that whatever components you want to be able
      // to render dynamically must be known to this module
      imports: [CommonModule, QRCodeModule,NgxBarcodeModule],
      declarations: [component]
    })
    class DynamicModule { }
    return DynamicModule;
  }
}